import { Badge } from "../ui/badge";

interface StatusBadgeProps {
    newsletter: INewsletter;
}

const StatusBadge: React.FC<StatusBadgeProps> = (props) => {
    const { newsletter } = props;
    return (
        <Badge
            variant="outline"
            className={`rounded-sm h-5 text-xs ${
                newsletter.is_published
                    ? "text-green-600 border-green-600 bg-green-50"
                    : "text-yellow-600 border-yellow-600 bg-yellow-100"
            }`}
        >
            {newsletter.is_published ? "live" : "not live"}
        </Badge>
    );
};

export default StatusBadge;
