"use client";

import NewsletterCardStructure from "./NewsletterCardStructure";
import Link from "next/link";
import { Button } from "../ui/button";
import { Export, PencilSimple } from "@phosphor-icons/react/dist/ssr";
import { Badge } from "lucide-react";
import StatusBadge from "./StatusBadge";
import ContextualHelpTooltip from "../ContextualHelpTooltip";
import { useRouter } from "next/navigation";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import React from "react";
import { getNumberOfSubscribersByNewsletterId } from "@/supabase/subscribers";

const NewsletterCard: React.FC<{ number: number; newsletter: INewsletter }> = ({
    newsletter,
    number,
}) => {
    const supabase = createClientComponentClient();

    const [numSubscribers, setNumSubscribers] = React.useState<number>(0);

    const getNumSubscribers = async () => {
        const subscribers = await getNumberOfSubscribersByNewsletterId(
            supabase,
            newsletter.id!
        );
        setNumSubscribers(subscribers);
    };

    React.useEffect(() => {
        getNumSubscribers();
    }, []);

    const router = useRouter();

    const onClick = async () => {
        if (newsletter.is_published) {
            // go to newsletter page
            router.push(`/${newsletter.route}`);
        } else {
            // edit newsletter
            router.push(`/home/newsletter/${newsletter.id}`);
        }
    };
    return (
        <NewsletterCardStructure
            isDashed={false}
            heading={newsletter.name}
            subHeading={`${numSubscribers} subscribers` ?? newsletter.subject}
            actionButton={
                <div className="flex flex-row gap-2 self-end">
                    <Link href={`/${newsletter.route}`}>
                        <ContextualHelpTooltip tooltipText="Go to newsletter page">
                            <Button
                                variant="outline"
                                size={"icon"}
                                disabled={!newsletter.is_published}
                            >
                                <Export size={22} />
                            </Button>
                        </ContextualHelpTooltip>
                    </Link>
                    <Link href={`/home/newsletter/${newsletter.id}`}>
                        <ContextualHelpTooltip tooltipText="Edit newsletter">
                            <Button variant="outline" size="icon">
                                <PencilSimple size={22} />
                            </Button>
                        </ContextualHelpTooltip>
                    </Link>
                </div>
            }
            topRightBadge={
                <div className="flex flex-row items-center gap-2">
                    <span className="text-sm text-gray-400">#{number}</span>
                    <StatusBadge newsletter={newsletter} />
                </div>
            }
            onClick={onClick}
        />
    );
};

export default NewsletterCard;
