"use client";
interface NewsletterCardStructureProps {
    heading: string;
    subHeading: string;
    isDashed: boolean;
    actionButton: React.ReactNode;
    topRightBadge?: React.ReactNode;
    onClick?: () => void;
}

const NewsletterCardStructure: React.FC<NewsletterCardStructureProps> = (
    props
) => {
    const {
        heading,
        subHeading,
        isDashed,
        actionButton,
        topRightBadge,
        onClick,
    } = props;

    return (
        <div
            className={`flex flex-col flex-grow gap-4 bg-white p-4 ${
                onClick ? "cursor-pointer" : ""
            } rounded-md shadow-md border ${
                isDashed ? "border-dashed border-gray-500" : ""
            } h-full justify-between relative`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                !!onClick && onClick();
            }}
        >
            {!!topRightBadge && (
                <div className="absolute top-4 right-4">{topRightBadge}</div>
            )}
            <div className="flex flex-col gap-2">
                <h2 className="text-xl font-bold w-[70%]">{heading}</h2>
                <p className="text-sm text-gray-500">{subHeading}</p>
            </div>

            <div className="self-end" onClick={(e) => e.stopPropagation()}>
                {actionButton}
            </div>
        </div>
    );
};

export default NewsletterCardStructure;
