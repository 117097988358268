import React from "react";
import {
    TooltipTrigger,
    TooltipProvider,
    Tooltip,
    TooltipContent,
} from "./ui/tooltip";

interface ContextualHelpTooltipProps {
    children: React.ReactNode;
    tooltipText: string;
}

function ContextualHelpTooltip({
    children,
    tooltipText,
}: ContextualHelpTooltipProps) {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>{children}</TooltipTrigger>
                <TooltipContent className="text-white">
                    <p>{tooltipText}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}

export default ContextualHelpTooltip;
